import { Box, Typography } from "@material-ui/core";
import { useLocation } from "react-router";

import {
  Header as GenericHeader,
  LanguageSelectDropdown,
} from "@hopper-b2b/ui";
import { useI18nContext } from "@hopper-b2b/i18n";

import { HeaderLogos } from "../MobileHeader";
import "./styles.scss";
import { CurrencySelectDropdown } from "../../../Currency";
import { IconName } from "../../../Icon";
import { IconComponent } from "../../../IconComponent";
import { PATH_FLY_FLAIR } from "../../../../utils/constants";
import { trackEvent } from "@hopper-b2b/api";
import { TAPPED_LINK, TappedLinkProperties } from "@hopper-b2b/types";
import clsx from "clsx";
import { DegradedPerformanceNotice } from "../DegradedPerformanceNotice";
import { DARK_HEADER_ROUTES } from "../../constants";

type DesktopHeaderProps = {
  displayPerfNotice?: boolean;
};

const DesktopHeader = ({ displayPerfNotice = false }: DesktopHeaderProps) => {
  const { brand, language, t } = useI18nContext();
  const dropdownLabel = brand.supportedLanguages.find(
    (l) => l.key === language
  );
  const location = useLocation();
  return (
    <>
      <GenericHeader
        className={clsx("b2b flair-generic-header app-header", {
          "with-performance-notice": displayPerfNotice,
          dark: DARK_HEADER_ROUTES.includes(location.pathname),
        })}
        fullWidth
        left={<HeaderLogos />}
        toolbarClassName="b2b"
        right={
          <Box className="home-page-menu-links right-section b2b">
            <LanguageSelectDropdown
              defaultLocale={language}
              supportedLanguages={brand.supportedLanguages}
              dropdownLabel={dropdownLabel.label}
              arrowIcon={<IconComponent name={IconName.ChevronDown} />}
            />
            <CurrencySelectDropdown />
            <Box>
              <a
                onClick={() => {
                  trackEvent({
                    eventName: TAPPED_LINK,
                    properties: {
                      current_url: window.location.origin,
                      link_href: PATH_FLY_FLAIR,
                      element_entry_point: "header",
                    } as TappedLinkProperties,
                  });
                }}
                className="fly-flair-link"
                href={PATH_FLY_FLAIR}
                target="_blank"
                rel="noreferrer"
              >
                <Typography variant="body2">
                  <span>{t("flyFlair")}</span>
                  <IconComponent name={IconName.ExternalLink} />
                </Typography>
              </a>
            </Box>
          </Box>
        }
      />
      {displayPerfNotice && <DegradedPerformanceNotice />}
    </>
  );
};

export default DesktopHeader;

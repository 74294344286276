import { defaultThemeConfigWithBasicColors } from "@hopper-b2b/themes";
import { asB2BMuiTheme } from "@hopper-b2b/themes";
import { ThemeOptions } from "@material-ui/core/styles";
import { LabComponentsPropsList } from "@material-ui/lab/themeAugmentation";

import AvertaStdRegularTtf from "../assets/fonts/AvertaStd-Regular.ttf";
import AvertaStdBlackTtf from "../assets/fonts/AvertaStd-Black.ttf";
import AvertaStdLightTtf from "../assets/fonts/AvertaStd-Light.ttf";
import AvertaStdSemiboldTtf from "../assets/fonts/AvertaStd-Semibold.ttf";

const flairColors = {
  ...defaultThemeConfigWithBasicColors,
  white: "#FFFFFF",
  // flair green
  "green-10": "#F7FFF5",
  "green-20": "#E5FFDF",
  "green-50": "#ACFF99",
  "green-60": "#5FCD4F",
  "green-80": "#38AC27",
  // flair purple
  "purple-10": "#F4E7FA",
  "purple-20": "#DBB3EE",
  "purple-50": "#8C0AC8",
  "purple-60": "#7008A0",
  "purple-80": "#540678",
  // flair black
  "black-10": "#EFEFEF",
  "black-20": "#DDDDDD",
  "black-50": "#C4C4C4",
  "black-60": "#868686",
  "black-80": "#606060",
  "black-100": "#000000",
  black: "#212529",
  // flair gray
  "gray-10": "#F7F7F7",
  "gray-20": "#DDDDDD",
  "gray-50": "#C4C4C4",
  "gray-80": "#737373",
  // flair yellow
  "yellow-50": "#D69A00",
  "yellow-60": "#BA7A00",
  "yellow-banner": "#FFEFD5",
  // flair red
  "red-50": "#A71313",
  "red-banner": "#FFEBEA",
  // state interactions
  "black-disabled": "#C4C4C4",
};
export const flairVariables = {
  ...flairColors,
  primary: "var(--black)",
  "info-details-popup-background": "var(--gray-10)",
  "primary-text": "var(--black)",
  "secondary-text": "var(--black-alpha-60)",
  "secondary-button-background": "var(--grey)",
  "primary-disabled": "var(--black-disabled)",
  "primary-hover": "var(--black-hover)",
  "button-background": "var(--primary)",
  "button-background-disabled": "var(--black-50)",
  "button-background-hover": "var(--primary-hover)",
  "button-border": "2px solid var(--primary)",
  "halfsheet-border-radius": "0.5rem",
  "mobile-banner": "var(--primary)",
  "loading-popup": "var(--white)",
  "link-text": "var(--purple-50)",
  "action-link-active-text": "var(--primary)",
  "hamburger-menu-color": "var(--primary)",
  "filter-background-selected": "var(--purple-20)",
  "card-tag-text": "var(--green-50)",
  "card-tag-background": "var(--green-10)",
  "bucket-0-color": "var(--white)",
  "bucket-1-color": "var(--white)",
  "bucket-2-color": "var(--white)",
  "bucket-3-color": "var(--white)",
  "info-details-popup-border": "var(--grey-10)",
  "inverse-text": "var(--white)",
  "input-label-text": "var(--primary-text)",
  "red-error-color": "var(--red-50)",
  "card-border": "2px solid var(--gray-10)",
  "card-border-thin": "1px solid var(--gray-10)",
  "success-color": "var(--citrus-20)",
  "pending-color": "var(--yellow-20)",
  "spinner-border-color": "var(--grey)",
  "spinner-top-border-color": "var(--primary)",
  "date-hover-color": "transparent",
  "warning-error-color-transparent": "var(--yellow-10)",
  "disruption-background-color": "var(--citrus-20)",
  "fare-details-banner": "var(--primary)",
  "fare-details-banner-text": "var(--primary-text)",
  "button-border-radius": "29px",
  "filter-button-border-radius": "6px",
  "border-radius": "4px",
  "border-radius-modal": "8px",
  "border-radius-cards": "12px",
  "border-radius-buttons": "24px",
  "border-default": "var(--gray-10)",
  "content-default": "var(--black-20)",
  "content-subtle": "var(--black-30)",
  "content-disabled": "var(--gray-40)",
  "surface-subtle": "var(--gray-10)",
  // calendar
  "date-selected-color": "var(--purple-10)",
  "date-selected-text-color": "var(--purple-50)",
  "date-text-color": "var(--primary-text)",
  "date-disabled-color": "var(--gray-20)",
  "date-calendar-border-radius": "63px",
  // header
  "header-primary": "var(--primary-text)",
  "header-travel-destination": "var(--white)",
  "header-travel-passenger": "var(--grey-10)",
  "app-bar-height": "64px",
  // search
  "search-field-label": "var(--primary-text)",
  "search-field-text": "var(--secondary-text)",
  // dropdown
  "dropdown-box-shadow":
    "0px 1px 5px 0px rgba(0, 0, 0, 0.02), 0px 2px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.14)",
  "plus-days-color": "var(--gray-80)",
};

const flairTheming: ThemeOptions & Partial<LabComponentsPropsList> = {
  palette: {
    type: "light",
    primary: {
      main: flairColors.black,
      contrastText: flairColors.white,
    },
    text: {
      primary: flairColors.black,
      secondary: flairColors["gray-80"],
      disabled: flairColors.white,
      hint: flairColors.white,
    },
    action: {
      disabled: flairColors["gray-80"],
    },
  },
  typography: {
    fontFamily: ["AvertaStd", "Arial"].join(","),
    htmlFontSize: 16,
    fontSize: 16,
    h1: {
      fontSize: "2.5rem",
      fontWeight: 900,
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 900,
    },
    h3: {
      fontSize: "1.75rem",
    },
    h4: {
      fontSize: "1.375rem",
      fontWeight: 700,
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: 600,
    },
    h6: {
      fontSize: "1rem",
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
    },
    body2: {
      fontSize: "1rem",
    },
    caption: {
      fontSize: "0.75rem",
    },
    overline: {
      fontSize: "1rem",
    },
    subtitle1: {
      fontSize: "0.875rem",
    },
    subtitle2: {
      fontSize: "1rem",
    },
    button: {
      fontSize: "1rem",
      lineHeight: "1.25rem",
      textTransform: "none" as const,
    },
  },
  shape: {
    borderRadius: 20,
  },
  spacing: [0, 4, 8, 16, 32, 64, 128],
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: "6px",
        boxShadow:
          "0px 1px 5px 0px rgba(0, 0, 0, 0.02), 0px 2px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.14)",
      },
    },
    MuiCssBaseline: {
      "@global": {
        "@font-face": [
          {
            fontFamily: "AvertaStd",
            fontStyle: "normal",
            fontWeight: 400,
            src: `
              local("AvertaStd Regular"),
              url(${AvertaStdRegularTtf}) format("truetype");
            `,
          },
          {
            fontFamily: "AvertaStd",
            fontStyle: "normal",
            fontWeight: 900,
            src: `
              local("AvertaStd Black"),
              url(${AvertaStdBlackTtf}) format("truetype");
            `,
          },
          {
            fontFamily: "AvertaStd",
            fontStyle: "normal",
            fontWeight: 100,
            src: `
              local("AvertaStd Light"),
              url(${AvertaStdLightTtf}) format("truetype");
            `,
          },
          {
            fontFamily: "AvertaStd",
            fontStyle: "normal",
            fontWeight: 600,
            src: `
              local("AvertaStd Semibold"),
              url(${AvertaStdSemiboldTtf}) format("truetype");
            `,
          },
        ],
      },
    },
    MuiButton: {
      root: {
        height: 50,
        fontWeight: 900,
        borderRadius: flairVariables["button-border-radius"],
      },
      sizeSmall: {
        height: 32,
      },
      sizeLarge: {
        height: 60,
        fontSize: "1rem",
        fontWeight: 700,
      },
      iconSizeSmall: {
        "& > *:first-child": {
          fontSize: 12,
        },
      },
      contained: {
        background: flairVariables["purple-50"],
        backgroundColor: flairVariables["purple-50"],
        "&:hover": {
          backgroundColor: flairVariables["purple-60"],
          borderColor: flairVariables["purple-60"],
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: flairVariables["purple-60"],
          },
        },
        "&:disabled": {
          color: flairColors.white,
          backgroundColor: flairVariables["primary-disabled"],
        },
      },
      containedSecondary: {
        backgroundColor: flairVariables.primary,
        "&:hover": {
          backgroundColor: flairVariables.primary,
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: flairVariables.primary,
          },
        },
      },
      containedPrimary: {
        backgroundColor: flairVariables["purple-50"],
        "&:hover": {
          backgroundColor: flairVariables["purple-60"],
          borderColor: flairVariables["purple-60"],
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: flairVariables.primary,
          },
        },
      },
      outlined: {
        backgroundColor: flairColors.white,
        borderColor: flairColors["gray-11"],
      },
      textPrimary: {
        color: flairColors["purple-50"],
        "&:hover": {
          color: flairColors["purple-60"],
          backgroundColor: "unset",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            color: flairColors["purple-60"],
            backgroundColor: "unset",
          },
        },
      },
      text: {
        color: flairColors["purple-50"],
        "&:hover": {
          color: flairColors["purple-60"],
          backgroundColor: "unset",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            color: flairColors["purple-60"],
            backgroundColor: "unset",
          },
        },
      },
      outlinedPrimary: {
        backgroundColor: flairColors.white,
        borderColor: flairVariables.primary,
        color: flairVariables.primary,
        border: "2px solid",
        borderRadius: "2rem",
        fontWeight: 700,
        "&:hover": {
          backgroundColor: flairColors["gray-10"],
          border: "2px solid",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: flairColors["gray-10"],
            border: "2px solid",
          },
        },
      },
    },
    MuiLink: {
      underlineHover: {
        "&:hover": {
          "text-decoration": "none",
        },
      },
    },
    MuiFilledInput: {
      root: {
        color: flairColors["primary-text"],
        height: 60,
        backgroundColor: flairColors.white,
        borderRadius: flairVariables["border-radius"],
        borderTopLeftRadius: flairVariables["border-radius"],
        borderTopRightRadius: flairVariables["border-radius"],
        border: `1px solid ${flairColors["gray-20"]}`,
        "&.Mui-focused, &.Mui-hover": {
          backgroundColor: flairColors.white,
        },
        "&:hover": {
          backgroundColor: "inherit",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: "inherit",
          },
        },
        "&:focus-within": {
          border: "1px solid var(--primary)",
        },
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 14,
      },
    },
    MuiInputBase: {
      root: {
        fontSize: "1rem",
      },
    },
    MuiAutocomplete: {
      popper: {
        borderRadius: flairVariables["border-radius"],
      },
      popupIndicator: {
        "& path": {
          fill: flairVariables["purple-50"],
        },
      },
      inputRoot: {
        "&[class*='MuiFilledInput-root']": {
          paddingLeft: 36,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        height: 60,
        backgroundColor: flairColors.white,
      },
      notchedOutline: {
        borderColor: flairColors["grey-11"],
      },
    },
    MuiFormControl: {
      root: {
        borderColor: flairColors["grey-11"],
      },
    },
    MuiRadio: {
      colorPrimary: {
        color: flairVariables["purple-50"],
        "&.Mui-checked": {
          color: flairVariables["purple-50"],
        },
      },
      colorSecondary: {
        color: flairVariables["purple-50"],
        "&.Mui-checked": {
          color: flairVariables["purple-50"],
        },
      },
      root: {
        color: flairVariables["purple-50"],
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: flairVariables["purple-50"],
        "&.Mui-checked": {
          color: flairVariables["purple-50"],
        },
      },
    },
    MuiSlider: {
      thumb: {
        border: "1px solid rgba(0, 0, 0, 0.14)",
        "&:hover,": {
          boxShadow: "none",
        },
        "&:focusVisible": {
          boxShadow: "none",
        },
        "&:active": {
          boxShadow: "none",
        },
      },
      root: {
        color: flairVariables["purple-50"],
      },
      rail: {
        color: flairVariables["purple-10"],
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          color: flairColors["purple-50"],
          backgroundColor: "white",
        },
        "&:hover": {
          backgroundColor: "red",
        },
      },
    },
    MuiMenuItem: {
      root: {
        "&:hover": {
          color: "inherit",
          backgroundColor: flairColors["purple-10"],
        },
        "&$selected": {
          color: flairColors["purple-50"],
          backgroundColor: "white",
        },
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: flairVariables["border-radius-modal"],
      },
    },
  },
};

export const muiTheme = asB2BMuiTheme(flairTheming);
